<template>
    <div class="addLabelName scrolling">
        <a-form :form="form">
            <a-form-item label="标签名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input
                    placeholder="请输入标签名称"
                    v-decorator="['name', { rules: [{ required: true, message: '请输入标签名称！' }] }]"
                />
            </a-form-item>
            <a-form-item label="标签顺序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input-number
                    placeholder="请输入排序"
                    style="width: 100%"
                    v-decorator="['sort', { initialValue: 100 }]"
                    :min="1"
                    :max="1000"
                />
            </a-form-item>
        </a-form>
        <div class="footer">
            <a-button @click="handleSubmit" type="primary" block>确定添加</a-button>
        </div>
    </div>
</template>
<script>
import { documentLableAdd } from '@/api/modular/fileStream/documentLabelManage'
export default {
    data() {
        return {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 15 },
            },
            form: this.$form.createForm(this),
        }
    },
    methods: {
        /**
         * 提交表单
         */
        handleSubmit() {
            const {
                form: { validateFields },
            } = this
            validateFields((errors, values) => {
                if (!errors) {
                    for (const key in values) {
                        if (typeof values[key] === 'object') {
                            values[key] = JSON.stringify(values[key])
                        }
                    }
                    documentLableAdd(values).then((res) => {
                        if (res.success) {
                            this.$message.success('新增成功')
                            this.$router.go(-1)
                        } else {
                            this.$message.error('新增失败') // + res.message
                        }
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.addLabelName {
    padding: 12px;
    .footer {
        position: fixed;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 45px;
    }
}
</style>
